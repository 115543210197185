.jbackground {
  background-image: url("https://cdn.discordapp.com/attachments/906597002907893761/961907551845384242/2020_1231_18_web.jpg");
  height: 100vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 100;
}

.jbackground2 {
  background-image: url("https://cdn.discordapp.com/attachments/906597002907893761/961917603314556928/2020_1231_22_web.jpg");
  height: 100vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 100;
}

.notification-top-right {
  position: absolute;
  right: 0;
  bottom: 0;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.centervideo {
  margin-left: 50vw;
  transform: translate(-50%);
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  /*background-color: #fafafa;*/
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.files-header {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

/* Variables */
:root {
  --gray-offset: rgba(0, 0, 0, 0.03);
  --gray-border: rgba(0, 0, 0, 0.15);
  --gray-light: rgba(0, 0, 0, 0.4);
  --gray-mid: rgba(0, 0, 0, 0.7);
  --gray-dark: rgba(0, 0, 0, 0.9);
  --green-light: #e0f2f1;
  --green-mid: #009688;
  --green-dark: #00766c;
  --body-color: var(--gray-mid);
  --headline-color: var(--gray-dark);
  --accent-color: #0066f0;
  /*--body-font-family: -apple-system, BlinkMacSystemFont, sans-serif;*/
  --radius: 6px;
}

.description-body {
  overflow-wrap: break-word;
}

.ham-style {
  position: "fixed";
  width: "36px";
  height: "30px";
  right: "36px";
  top: "36px";
}

.background-image-hor {
  background-image: url("https://cdn.discordapp.com/attachments/872882586425376768/941746681819590676/stayc_hor.jpg");
}

.background-image-vert {
  background-image: url("https://cdn.discordapp.com/attachments/872882586425376768/941749381240082432/stayc_vert.jpg");
}

.blackfooter {
  background: #000000;
}

.absolute-bottom {
  position: absolute;
  bottom: 10px;
  left: 0px;
  right: 0px;
}

/* Base */
html {
  box-sizing: border-box;
  overflow-x: hidden;
}
* {
  box-sizing: inherit;
}

/*
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--body-color);
  margin-top: 2px;
  margin-bottom: 4px;
}*/
h1 {
  font-size: 27px;
  color: var(--headline-color);
}
h4 {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-light);
}

@media (min-width: 600px) .HomepageHeroGradient {
  --transformOriginX: calc(var(--gutterWidth) * 0.8);
}

/* table font size */
.table-font-size {
  font-size: min(2vw, 1rem);
}

/* modal tooltip */
.modal-tip {
  z-index: 1000001 !important;
}

/* modal prevent scrolling when open */
.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content {
  background: white;
  width: 50rem;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  position: relative;
}

/* rich editor draft.js */
.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: inherit;
  font-size: 14px;
  padding: 15px;
  overflow-y: auto;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.DraftEditor-editorContainer,
.public-DraftEditor-content {
  height: 100%;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

/* discord icon */
.discord-icon {
  background-image: url("assets/img/brand/discord_single.svg");
}
.home-background {
  background: #273b7f;
  background: -webkit-linear-gradient(left top, #273b7f, #892017);
  background: -moz-linear-gradient(left top, #273b7f, #892017);
  background: linear-gradient(to right bottom, #273b7f, #892017);
}

.home-background-inverted {
  background: #273b7f;
  background: -webkit-linear-gradient(left top, #273b7f, #892017);
  background: -moz-linear-gradient(left top, #273b7f, #892017);
  background: linear-gradient(to right top, #273b7f, #892017);
}

.h2ome-background {
  background: #5d01ca;
  background: -webkit-linear-gradient(left, #5d01ca, #0c345b);
  background: -moz-linear-gradient(left, #5d01ca, #0c345b);
  background: linear-gradient(to right, #5d01ca, #0c345b);
}
.h1ome-background {
  background: #b92b27; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #b92b27,
    #1565c0
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #b92b27,
    #1565c0
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
/* shop background */
.animate-home-background {
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

/* shop background */
.custom-shop-background {
  background: #3151c2;
  background: -webkit-linear-gradient(top left, #3151c2, #de2313);
  background: -moz-linear-gradient(top left, #3151c2, #de2313);
  background: linear-gradient(to bottom right, #3151c2, #de2313);
}

.control-panel-background {
  background: #97c3dd;
  background: -webkit-linear-gradient(top left, #97c3dd, #432add);
  background: -moz-linear-gradient(top left, #97c3dd, #432add);
  background: linear-gradient(to bottom right, #97c3dd, #432add);
}

.portal-background {
  background: #97c3dd;
  background: -webkit-linear-gradient(top left, #97c3dd, #432add);
  background: -moz-linear-gradient(top left, #97c3dd, #432add);
  background: linear-gradient(to bottom right, #97c3dd, #432add);
}

.onboard-background {
  background: #97c3dd;
  background: -webkit-linear-gradient(top left, #97c3dd, #432add);
  background: -moz-linear-gradient(top left, #97c3dd, #432add);
  background: linear-gradient(to bottom right, #97c3dd, #432add);
}

.login-background {
  background: #97c3dd;
  background: -webkit-linear-gradient(top left, #97c3dd, #432add);
  background: -moz-linear-gradient(top left, #97c3dd, #432add);
  background: linear-gradient(to bottom right, #97c3dd, #432add);
}

.logout-background {
  background: #97c3dd;
  background: -webkit-linear-gradient(top left, #97c3dd, #432add);
  background: -moz-linear-gradient(top left, #97c3dd, #432add);
  background: linear-gradient(to bottom right, #97c3dd, #432add);
}

.footer-background {
  background: #efeae1;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.center-get-started {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

p {
  font-weight: 400;
}

/* Layout */
.sr-root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-width: 320px;
  height: auto;
  min-height: 100vh;
  padding: 48px 20px;
}
.sr-header {
  margin-top: 64px;
  margin-bottom: 32px;
}
.sr-payment-summary {
  margin-bottom: 20px;
  text-align: center;
}
.sr-main,
.sr-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.sr-main {
  width: 100%;
}
.sr-content {
  display: none;
  padding-left: 48px;
}
.sr-header__logo {
  height: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
}
.sr-legal-text {
  color: var(--gray-light);
  text-align: center;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}
.sr-field-error {
  color: var(--accent-color);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

/* Buttons and links */
button {
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  /*margin-top: 16px;*/
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
button:hover {
  filter: contrast(115%);
}
button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}

/* Responsiveness */
@media (min-width: 720px) {
  .sr-root {
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }

  .sr-header__logo {
    background-position: left top;
  }

  .sr-payment-summary {
    text-align: left;
  }

  .sr-content {
    display: block;
  }

  .sr-main {
    width: auto;
  }
}

/* shop */
.shopimage {
  object-fit: contain;
}

.align-center {
  text-align: center;
  color: white;
  margin-bottom: 50px;
}

.sr-header__logo:after {
  content: "generic store";
  padding-left: 32px;
  display: block;
  color: white;
  font-size: 22px;
  font-weight: 600;
}

.sr-main h1 {
  font-size: x-large;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 32px;
  text-align: center;
}

.price-table-container {
  display: flex;
  box-shadow: 0 2px 5px 0 rgba(60, 66, 87, 0.08),
    0 1px 1px 0 rgba(0, 0, 0, 0.12);
  flex-wrap: wrap;
  justify-content: center;
}

.price-table-container section {
  border-radius: 6px;
  padding: 32px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  background-color: #ffffff;
}
/*
.price-table-container section:first-of-type {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 1px solid #efefef;
}

.price-table-container section:last-of-type {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
*/
.price-table-container button {
  background-color: #f0f0f0;
  color: #000000;
  border-radius: 64px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.price-table-container button:hover {
  background-color: #d6d6d6;
  filter: none;
}

.price-table-container .name {
  color: #7f7f7f;
  font-size: large;
  margin-top: 8px;
  text-align: center;
}

.price-table-container .price {
  color: #000000;
  font-size: xx-large;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 8px;
  text-align: center;
}

.price-table-container .duration {
  color: #969696;
  font-size: smaller;
  text-align: center;
}

.error-message {
  background-color: #000;
  color: #fff;
  display: none;
  font-weight: bold;
  left: 0;
  padding: 10px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
}

.completed-view-section {
  background-color: white;
  border-radius: 16px;
  padding: 20px;
}

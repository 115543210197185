/*
Theme Name: Highup Ent.
Author: Fivement
Author URI: https://www.fivement.com/
Description: Wordpress theme for Highup Ent.
Version: 1.0.0
Text Domain: highup
*/

@font-face {
    font-family: 'Gilroy';
    src: url("../STAYC - High Up Ent._files/Gilroy-ExtraBold.eot");
    src: url(http://www.highup-ent.com/wp-content/themes/highup/fonts/Gilroy-ExtraBold.eot?#iefix) format('embedded-opentype'),
         url("../STAYC - High Up Ent._files/Gilroy-ExtraBold.woff") format('woff'),
         url("../STAYC - High Up Ent._files/Gilroy-ExtraBold.ttf") format('truetype'),
         url("../STAYC - High Up Ent._files/Gilroy-ExtraBold.svg#Gilroy-ExtraBold") format('svg');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/*--- Reset ---*/

* {
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
		box-sizing: border-box;
}
*:before,
*:after {
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
		box-sizing: border-box;
}

html, body, div, ul, ol, li, h1, h2, h3, h4, h5, h6, form, p, fieldset, input, textarea, abbr, article, figure, button {
	margin: 0;
	padding: 0;
}
html, body {
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}
body {
	background-color: #000;
	color: #fff;
	font-size: 15px;
	font-family: 'Raleway', 'Poppins', 'Noto Sans KR', sans-serif;
	font-weight: 400;
	line-height: 1.65;
	word-break: keep-all;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
a {
	color: inherit;
	text-decoration: none;
}
ul, li {
	list-style: none;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Gilroy', 'Noto Sans KR', sans-serif;
	text-transform: uppercase;
}
h1 {
	font-size: 70px;
	letter-spacing: 1px;
	line-height: 1.1;
}
h2 {
	font-size: 45px;
	line-height: 1.3;
}
h3 {
	font-size: 32px;
}
h4 {
	font-size: 22px;
}
p {
	font-size: 18px;
	line-height: 1.8;
}

@media (max-width: 1400px) {
	h1 {
		font-size: 60px;
	}
	h3 {
		font-size: 30px;
	}
}

@media (max-width: 1100px) {
	h1 {
		font-size: 50px;
		letter-spacing: 0;
	}
	h2 {
		font-size: 40px;
	}
	p {
		font-size: 16px;
	}
}

@media (max-width: 600px) {
	body {
		font-size: 14px;
	}
	h1 {
		font-size: 35px;
		letter-spacing: -0.5px;
	}
	h2 {
		font-size: 30px;
	}
	h3 {
		font-size: 22px;
	}
	h4 {
		font-size: 18px;
	}
	p {
		font-size: 14px;
	}
}

/*--- Site header ---*/

.site-header {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
	padding: 65px 80px 55px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
}
.site-header .site-logo {
	width: 120px;
	height: 60px;
	overflow: hidden;
	position: relative;
	z-index: 99;
}
.site-header .site-logo .logo-holder {
	position: relative;
	transition: margin-top 0.5s cubic-bezier(0.25, 1, 0.5, 1);
	width: 100%;
}
body.nav-opened .site-header .site-logo .logo-holder {
	transition: margin-top 0.9s cubic-bezier(0.25, 1, 0.5, 1);
}
.site-header .site-logo a {
	background-size: cover;
	background-position: left center;
	display: block;
	width: 100%;
	padding-top: 50%;
	position: relative;
	transition: opacity 0.3s ease-out;
}
.site-header .site-logo .highup-logo {
	background-image: url('http://cdn.highup-ent.com/assets/images/site-logo-highup.png');
}
.site-header .site-logo .artist-logo {
	top: -10px;
}
.site-header .site-logo .artist-logo.sairo {
	background-image: url('http://cdn.highup-ent.com/assets/images/site-logo-artist-sairo.png');
}
.site-header .site-logo .artist-logo.stayc {
	background-image: url("../STAYC - High Up Ent._files/site-logo-artist-stayc.png");
}
body.artist.nav-opened .site-header .site-logo .logo-holder,
body.discography.nav-opened .site-header .site-logo .logo-holder {
	margin-top: -60px;
}
body.artist .site-header .site-logo .highup-logo,
body.artist.nav-opened .site-header .site-logo .artist-logo,
body.discography .site-header .site-logo .highup-logo,
body.discography.nav-opened .site-header .site-logo .artist-logo {
	opacity: 0;
}
body.artist.nav-opened .site-header .site-logo .highup-logo,
body.discography.nav-opened .site-header .site-logo .highup-logo {
	opacity: 1;
}

.hamburger {
	background-color: transparent;
	border: 0;
	cursor: pointer;
	margin: 0;
	overflow: visible;
	text-transform: none;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	width: 40px;
	height: 40px;
	padding: 5px;
	position: relative;
	left: 5px;
	z-index: 99;
}
.hamburger .bun {
	width: 30px;
	height: 30px;
	display: inline-block;
	position: relative;
}

.hamburger .patty {
	background-color: transparent;
	display: block;
	top: 50%;
}
.hamburger .patty,
.hamburger .patty::before,
.hamburger .patty::after {
	width: 30px;
	height: 3px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
}
.hamburger .patty::before,
.hamburger .patty::after {
	content: "";
	display: block;
	background-color: #fff;
}
.hamburger .patty::before {
	top: -6px;
	transition: top 0.075s 0.12s ease, opacity 0.075s ease;
	width: 22px;
}
.hamburger .patty::after {
	bottom: -6px;
	transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

body.nav-opened .hamburger .patty {
	background-color: #fff;
	transform: rotate(45deg);
	transition-delay: 0.12s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
body.nav-opened .hamburger .patty::before {
	top: 0;
	opacity: 0;
	transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
body.nav-opened .hamburger .patty::after {
	bottom: 0;
	transform: rotate(-90deg);
	transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 1400px) {
	.site-header {
		padding: 65px 70px 55px;
	}
	.site-header .site-logo {
		width: 100px;
		height: 50px;
	}
	body.artist.nav-opened .site-header .site-logo .logo-holder,
	body.discography.nav-opened .site-header .site-logo .logo-holder {
		margin-top: -50px;
	}
}

@media (max-width: 1100px) {
	.site-header {
		flex-direction: row;
		width: 100%;
		height: auto;
		padding: 25px 35px;
	}
	.site-header .site-logo {
		width: 105px;
		height: 40px;
	}
	.site-header .site-logo a {
		width: 80px;
		padding-top: 40px;
	}
	.site-header .site-logo .artist-logo {
		top: 0;
	}
	.site-header .site-logo .artist-logo.stayc {
		width: 105px;
	}
	body.artist.nav-opened .site-header .site-logo .logo-holder,
	body.discography.nav-opened .site-header .site-logo .logo-holder {
		margin-top: -40px;
	}
	.site-header .hamburger {
		left: 10px;
		transform: scale(0.85);
	}
	.hamburger .patty::before {
		left: 8px;
	}
}

@media (max-width: 600px) {
	.site-header {
		padding: 20px;
	}
	.site-header .site-logo {
		width: 90px;
		height: 35px;
	}
	.site-header .site-logo a {
		width: 70px;
		padding-top: 35px;
	}
	.site-header .site-logo .artist-logo.stayc {
		width: 90px;
	}
	body.artist.nav-opened .site-header .site-logo .logo-holder,
	body.discography.nav-opened .site-header .site-logo .logo-holder {
		margin-top: -35px;
	}
	.site-header .hamburger {
		top: -3px;
		left: 10px;
		transform: scale(0.75);
	}
}

.site-navigation {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 98;
	transform: translateX(-100%);
}
body.nav-opened .site-navigation {
	transform: translateX(0);
}
.site-navigation .site-menu .menu-item {
	font-family: 'Gilroy', 'Noto Sans KR', sans-serif;
	font-size: 50px;
	line-height: 1.5;
	text-transform: uppercase;
	position: relative;
}
.site-navigation .site-menu .menu-item a {
	display: flex;
	align-items: flex-start;
}
.site-navigation .site-menu .menu-item .menu-label {
	font-size: 13px;
	margin-top: 15px;
	margin-left: 15px;
}
.site-navigation .site-languages {
	margin-top: 20px;
}
.site-navigation .site-languages .menu-item {
	display: inline-block;
	font-family: 'Gilroy', 'Noto Sans KR', sans-serif;
	font-size: 25px;
	margin-right: 20px;
}
.site-navigation .site-languages .menu-item a {
	opacity: 0.35;
	transition: opacity 0.3s ease-out;
}
.site-navigation .site-languages .menu-item a:hover {
	opacity: 0.5;
}
.site-navigation .site-languages .menu-item.current-language a {
	opacity: 1;
}

body.nav-opened .site-navigation .menu-item {
    animation: menu-fade-in 0.35s linear both;
}

@keyframes menu-fade-in {
	from {
		opacity: 0;
		transform: translate3d(0, 10px, 10px);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@media (max-width: 1100px) {
	.site-navigation .site-menu .menu-item {
		font-size: 40px;
	}
}

@media (max-width: 600px) {
	.site-navigation .site-menu .menu-item {
		font-size: 30px;
	}
	.site-navigation .site-menu .menu-item .sub-name {
		font-size: 13px;
		margin-top: 7px;
		margin-left: 5px;
	}
	.site-navigation .site-menu .menu-item .menu-label {
		font-size: 12px;
		opacity: 0.5;
		margin-top: 8px;
		margin-left: 7px;
	}
	.site-navigation .site-languages .menu-item {
		font-size: 20px;
		margin-right: 12px;
	}
}

/*--- Site Components ---*/

.site-components > * {
	bottom: 60px;
	font-size: 13px;
	line-height: 25px;
	overflow: hidden;
	position: fixed;
	z-index: 9;
}
.site-components .category {
	height: 25px;
	left: 360px;
	text-transform: capitalize;
}
.site-components .number {
	display: flex;
	right: 80px;
}
.site-components .number .current {
	height: 25px;
}
.site-components .number .of {
	margin: 0 5px;
}
.site-components .category span,
.site-components .number .current span {
	display: block;
	margin-bottom: 25px;
}
.site-components .category .category-label,
.site-components .number .current-number {
	position: relative;
	transition: margin-top 0.8s cubic-bezier(0.5, 1, 0.89, 1);
}

.site-components .section-line,
.site-components .nav-line {
	background-color: rgba(255,255,255,0.15);
	position: fixed;
	z-index: 89;
}
.site-components .section-line {
	width: calc(100% - 282px);
	height: 2px;
	bottom: 250px;
	right: 0;
	transition: width 2s cubic-bezier(0.16, 1, 0.3, 1);
}
.site-components .nav-line {
	width: 2px;
	height: 100%;
	top: 0;
	left: 280px;
	transition: height 2s cubic-bezier(0.16, 1, 0.3, 1);
}
body.loading .site-components .section-line {
	width: 0;
}
body.loading .site-components .nav-line {
	height: 0;
}
body.loaded .site-components .section-line,
body.loaded .site-components .nav-line {
	transition: none;
}

.site-components .breadcrumbs {
	font-size: 10px;
	overflow: visible;
	position: fixed;
	top: 65px;
	right: 70px;
	width: 0;
	letter-spacing: 0.5px;
	transform: rotate(90deg);
	transform-origin: top left;
	white-space: nowrap;
}
.site-components .breadcrumbs .dash {
	display: inline-block;
	margin: 0 10px;
	transition: margin 2s cubic-bezier(0.16, 1, 0.3, 1);
}
body.loading .site-components .breadcrumbs .dash {
	margin: 0 15px;
}

@media (max-width: 1400px) {
	.site-components .category {
		left: 320px;
	}
	.site-components .section-line {
		width: calc(100% - 242px);
	}
	.site-components .nav-line {
		left: 240px;
	}
}

@media (max-width: 1100px) {
	.site-components > * {
		bottom: 40px;
	}
	.site-components .category {
		left: 50px;
	}
	.site-components .number {
		right: 50px;
	}
	.site-components .section-line {
		display: none;
	}
	.site-components .nav-line {
		width: 100%;
		height: 2px;
		bottom: auto;
		top: 90px;
		left: 0;
		transition: width 2s cubic-bezier(0.16, 1, 0.3, 1);
	}
	body.loading .site-components .nav-line {
		width: 0;
	}
	.site-components .breadcrumbs {
		top: 130px;
		right: 25px;
	}
}

@media (max-width: 600px) {
	.site-components > * {
		bottom: 15px;
		font-size: 10px;
	}
	.site-components .category {
		left: 21px;
	}
	.site-components .number {
		right: 20px;
	}
	.site-components .nav-line {
		top: 75px;
	}
	.site-components .breadcrumbs {
		font-size: 8px;
		top: 110px;
		right: 10px;
	}
}

/*--- Curtains ---*/

.loading-curtain,
.nav-curtain {
	background-color: #000;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 90;
}

.loading-curtain {
	transition: opacity 2s cubic-bezier(0.25, 1, 0.5, 1);
	pointer-events: none;
}
body.loading .loading-curtain {
	opacity: 1;
}
body.loaded .loading-curtain {
	opacity: 0;
	transform: translateX(-100%);
}

.nav-curtain {
	transition: opacity 0.6s cubic-bezier(0.25, 1, 0.5, 1), transform 0s linear 0.6s;
	transform: translateX(-100%);
}
body.nav-opened .nav-curtain {
	opacity: 0.95;
	transition: opacity 0.6s cubic-bezier(0.25, 1, 0.5, 1);
	transform: translateX(0);
}

/*--- Cover Images ---*/

.background {
	background-color: #000;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
}
.background > * {
	opacity: 0.85;
	position: absolute;
}
.background .video {
	top: 50%;
	left: 50%;
	width: auto;
	height: auto;
	transform: translate(-50%, -50%);
	z-index: 3;
}
.background .image {
	background-size: cover;
	background-position: center center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 1;
}
.background .image.portrait {
	display: none;
	z-index: 2;
}

@media (max-width: 1100px) and (orientation: portrait) {
	.background .image.landscape {
		display: none;
	}
	.background .image.portrait {
		display: block;
	}
}

@media (min-aspect-ratio: 16/9) {
	.background .video {
		width: 100%;
	}
}

@media (max-aspect-ratio: 16/9) {
	.background .video {
		height: 100%;
	}
}

/*--- List ---*/

.list-item:first-child {
	border-top: 2px solid rgba(255,255,255,0.15);
}
.list-item {
	border-bottom: 2px solid rgba(255,255,255,0.15);
	display: flex;
	flex-wrap: wrap;
	font-size: 17px;
	padding: 20px 10px;
	position: relative;
}
.list-item span {
	display: inline-block;
	width: 50%;
}
.list-item span:last-child {
	opacity: 0.5;
	padding-left: 30px;
}

@media (max-width: 600px) {
	.list-item {
		font-size: 14px;
		padding: 15px 5px;
	}
	.list-item span {
		width: 100%;
	}
	.list-item span:last-child {
		padding-left: 0;
	}
}

/*--- Main ---*/

.main-feed .section {
	background-size: cover;
	background-position: center center;
}
.main-feed .content {
	display: none;
}
.main-feed .copy {
	top: 0;
	left: 280px;
	position: absolute;
	z-index: 9;
}
.main-feed .copy .copy-text {
	display: inline-block;
	padding: 60px 80px;
}
.main-feed .copy .headline {
	margin-bottom: 45px;
	text-transform: uppercase;
}
.main-feed .copy .subhead {
	font-size: 20px;
	line-height: 1.5;
	margin-left: 3px;
}

.main-feed .copy .text-mask {
	overflow: hidden;
}
.main-feed .copy .text-mask span {
	display: block;
	transition: opacity 1.5s cubic-bezier(0.25, 1, 0.5, 1), transform 1.2s cubic-bezier(0.25, 1, 0.5, 1);
	transition-delay: 0.2s;
	opacity: 1;
}
.main-feed .copy .text-mask:nth-child(2) span {
	transition-delay: 0.3s;
}
.main-feed .copy .text-mask:nth-child(3) span {
	transition-delay: 0.4s;
}
.main-feed .copy .text-mask:nth-child(4) span {
	transition-delay: 0.5s;
}
.main-feed .copy .text-mask:nth-child(5) span {
	transition-delay: 0.6s;
}

.main-feed .copy.hidden .text-mask span {
	opacity: 0;
	transform: translateY(30px);
}

@media (max-width: 1400px) {
	.main-feed .copy {
		left: 240px;
	}
}

@media (max-width: 1100px) {
	.main-feed .copy {
		top: auto;
		bottom: 100px;
		left: 0;
	}
	.main-feed .copy .copy-text {
		padding: 50px;
	}
	.main-feed .copy .headline {
		margin-bottom: 30px;
	}
	.main-feed .copy .subhead {
		font-size: 17px;
		margin-left: 0;
	}
}

@media (max-width: 600px) {
	.main-feed .copy {
		bottom: 60px;
		width: 100%;
	}
	.main-feed .copy .copy-text {
		padding: 20px;
		width: 100%;
	}
	.main-feed .copy .headline {
		margin-bottom: 20px;
	}
	.main-feed .copy .subhead {
		font-size: 15px;
	}
}

/*--- Page Layouts ---*/

.outer-margin {
	padding-left: 282px;
}
.section-content {
	padding-left: 100px;
	padding-right: 380px;
}
.section-liner {
	position: relative;
}
.section-liner > span {
	background-color: rgba(255,255,255,0.15);
	content: '';
	display: block;
	width: 0;
	height: 2px;
	position: absolute;
	right: 0;
	transition: width 2s cubic-bezier(0.16, 1, 0.3, 1);
}
.section-liner .top-line {
	top: 0;
}
.section-liner .bottom-line {
	bottom: 0;
	transition-delay: 0.3s;
}
.section.active .section-liner > span {
	width: 100%;
}

@media (max-width: 1400px) {
	.outer-margin {
		padding-left: 242px;
	}
	.section-content {
		padding-left: 80px;
		padding-right: 80px;
	}
}

@media (max-width: 1100px) {
	.outer-margin {
		padding-left: 0;
	}
	.section-content {
		padding: 0 45px;
	}
}

@media (max-width: 600px) {
	.section-content {
		padding-left: 30px;
	}
}

.page-cover {
	height: calc(100vh + 60px);
	height: calc((var(--vh, 1vh) * 100) + 60px);
	position: relative;
}
.page-cover .outer-margin {
	display: flex;
	align-items: flex-end;
	height: 100%;
	padding-bottom: 120px;
}
.page-cover .headline {
	position: relative;
	z-index: 2;
}
.page-cover .headline .text-mask {
	overflow: hidden;
  overflow-wrap: normal;
}
.page-cover .headline .text-mask span {
	display: block;
	transition: opacity 1.2s cubic-bezier(0.25, 1, 0.5, 1), transform 2s cubic-bezier(0.25, 1, 0.5, 1);
	transition-delay: 0.2s;
	opacity: 1;
}
.page-cover .headline .text-mask:nth-child(2) span {
	transition-delay: 0.4s;
}
.page-cover .headline .text-mask:nth-child(3) span {
	transition-delay: 0.6s;
}
.page-cover .headline .text-mask:nth-child(4) span {
	transition-delay: 0.8s;
}
.page-cover .headline .text-mask:nth-child(5) span {
	transition-delay: 1s;
}

body.loading .page-cover .headline .text-mask span {
	opacity: 0;
	transform: translateY(30px);
}
body.loading .page-cover .animate-copy .text-mask:nth-child(2) span {
	transform: translateY(40px);
}
body.loading .page-cover .animate-copy .text-mask:nth-child(3) span {
	transform: translateY(50px);
}
body.loading .page-cover .animate-copy .text-mask:nth-child(4) span {
	transform: translateY(60px);
}
body.loading .page-cover .animate-copy .text-mask:nth-child(5) span {
	transform: translateY(70px);
}

.page-content .section {
	margin: 120px 0;
}
.section .animate {
	opacity: 0;
	transform: translate3d(0px, 30px, 0px);
}
.section.active .animate {
	opacity: 1;
	transform: translate3d(0px, 0px, 0px);
	transition: opacity 1.5s cubic-bezier(0.25, 1, 0.5, 1), transform 1.5s cubic-bezier(0.25, 1, 0.5, 1);
}
.section .animate[data-offset="50"] {
	transform: translate3d(0px, 50px, 0px);
}
.section .animate[data-duration="1"] {
	transition: opacity 1.2s cubic-bezier(0.25, 1, 0.5, 1), transform 1s cubic-bezier(0.25, 1, 0.5, 1);
}
.section .animate[data-duration="2"] {
	transition: opacity 2s cubic-bezier(0.25, 1, 0.5, 1), transform 2s cubic-bezier(0.25, 1, 0.5, 1);
}
.section .animate[data-delay="0.3"] {
	transition-delay: 0.3s;
}
.section .animate[data-delay="0.4"] {
	transition-delay: 0.4s;
}
.section .animate[data-delay="0.5"] {
	transition-delay: 0.5s;
}
.section .animate[data-delay="0.6"] {
	transition-delay: 0.6s;
}
.section .animate[data-delay="0.7"] {
	transition-delay: 0.7s;
}
.section .animate[data-delay="0.8"] {
	transition-delay: 0.8s;
}
.section .animate[data-delay="0.9"] {
	transition-delay: 0.9s;
}
.section .animate[data-delay="1"] {
	transition-delay: 1s;
}

.will-change {
	will-change: opacity, transform;
}

.section .section-heading {
	margin-left: 100px;
	margin-bottom: 45px;
}
.page-content .section h4 {
	line-height: 1.15;
	margin: 25px 0 15px;
}
.page-content .section p {
	margin-bottom: 30px;
}
.page-content .section h4 + p {
	font-size: 15px;
	margin-bottom: 0;
}
.page-content .section.active h4 + p {
	opacity: 0.5 !important;
}

@media (max-width: 1400px) {
	.section .section-heading {
		margin-left: 80px;
	}
}

@media (max-width: 1100px) {
	.page-cover {
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
	}
	.page-cover .outer-margin {
		padding-bottom: 80px;
	}
	.page-content .section {
		margin: 80px 0;
	}
	.section .section-heading {
		margin-left: 50px;
	}
}

@media (max-width: 600px) {
	.page-cover .outer-margin {
		padding-bottom: 60px;
	}
	.page-content .section {
		margin: 60px 0;
	}
	.section .section-heading {
		margin-left: 30px;
		margin-bottom: 30px;
	}
	.page-content .section h4 {
		margin: 20px 0 10px;
	}
	.page-content .section p {
		margin-bottom: 20px;
		/*word-break: break-all;*/

		/*text-align: justify;*/
	}
	.page-content .section h4 + p {
		font-size: 13px;
	}
	.page-content .section p > br {
		display: none;
	}
	.page-content .section h4 + p > br {
		display: block;
	}
}

.page-source {
	display: none;
}

/*--- Producer / Artist ---*/

.manifesto .section-heading {
	font-weight: 900;
	line-height: 1.35;
}

.social-links {
	margin-top: 40px;
}
.social-links span {
	display: block;
	font-size: 14px;
	opacity: 0.5;
	margin-right: 20px;
}
.social-links .text-button {
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	margin-top: 5px;
	margin-right: 15px;
}

.profile .outer-margin {
	padding-right: 0;
}
.profile .section-content {
	padding: 0;
}
.profile .section-heading {
	margin-bottom: 15px;
}
.profile .profile-grid {
	display: flex;
	flex-wrap: wrap;
	width: calc(100% - 420px);
}
.profile .profile-grid .grid-item {
	width: 50%;
	padding: 30px;
	transform: translateX(70px);
}
.profile .profile-grid .grid-item img {
	border-radius: 3px;
	width: 100%;
	height: auto;
}

body.producer .profile .profile-grid .grid-item h4 {
	margin-bottom: 5px;
}

@media (max-width: 1400px) {
	.profile .profile-grid {
		padding: 0 50px;
		width: 100%;
	}
	.profile .profile-grid .grid-item {
		width: 50%;
		transform: translateX(0);
	}
}

@media (max-width: 1100px) {
	.profile .profile-grid .grid-item {
		transform: translateX(-30px);
	}
}

@media (max-width: 900px) {
	.profile .profile-grid .grid-item {
		padding: 20px;
		transform: translateX(-20px);
	}
}

@media (max-width: 600px) {
	.social-links span {
		font-size: 13px;
	}
	.social-links .text-button {
		font-size: 15px;
		margin-right: 10px;
	}
	.profile .profile-grid {
		padding-left: 0;
		padding-right: 60px;
	}
	.profile .profile-grid .grid-item {
		width: 100%;
		padding: 10px;
		transform: translateX(20px);
	}
}


.discography.section .outer-margin {
	padding-right: 0;
}
.discography.section .headline {
	font-weight: 900;
}
.discography.section .section-heading {
	margin-bottom: 15px;
}
.discography .album-list {
	margin-top: 15px;
}
.discography .album-list * {
	outline: none;
}
.discography .album-list .slick-track {
	margin: 0;
}
.discography .album-list .album-item {
	padding: 30px;
	/*transform: translateX(calc(-100% - 140px));*/
}
/* .discography .album-list.has-one-item .album-item {
	transform: translateX(calc(-50% - 140px));
}
.discography .album-list.has-two-items .album-item {
	transform: translateX(-140px);
} */
.discography .album-list .album-item img {
	border-radius: 3px;
	width: 100%;
	height: auto;
}

/* @media (max-width: 1400px) {
	.discography .album-list .album-item {
		transform: translateX(-100%);
	}
	.discography .album-list.has-one-item .album-item {
		transform: translateX(-50%);
	}
	.discography .album-list.has-two-items .album-item {
		transform: translateX(0);
	}
}

@media (max-width: 1100px) {
	.discography .album-list .album-item {
		transform: translateX(calc(-100% - 30px));
	}
	.discography .album-list.has-one-item .album-item {
		transform: translateX(calc(-50% - 30px));
	}
	.discography .album-list.has-two-items .album-item {
		transform: translateX(-30px);
	}
}

@media (max-width: 900px) {
	.discography .album-list .album-item {
		padding: 20px;
		transform: translateX(calc(-100% - 20px));
	}
	.discography .album-list.has-one-item .album-item {
		transform: translateX(calc(-50% - 20px));
	}
	.discography .album-list.has-two-items .album-item {
		transform: translateX(-20px);
	}
}

@media (max-width: 600px) {
	.discography .album-list .album-item {
		padding: 10px;
		transform: translateX(-10px) !important;
	}
} */

.schedule .section-liner {
	min-height: 500px;
	padding: 65px 0;
}
.schedule .section-content {
	transition: opacity 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}
.schedule .section-content:after {
	content: '';
	clear: both;
	display: table;
}
.schedule .section-content.loading {
	opacity: 0.3;
	pointer-events: none;
}
.schedule .section-content > * {
	width: 50%;
}
.schedule .schedule-nav,
.schedule .event-list {
	float: left;
}
.schedule .schedule-nav {
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 22px;
	margin-left: -10px;
}
.schedule .schedule-nav h3 {
	display: inline-block;
	margin: 0 15px;
}
.schedule .schedule-nav .button {
	background-size: contain;
	cursor: pointer;
	display: inline-block;
	width: 32px;
	height: 32px;
	opacity: 0.35;
	transition: opacity 0.2s ease-out;
}
.schedule .schedule-nav .button:hover {
	opacity: 0.7;
}
.schedule .schedule-nav .prev-month {
	background-image: url('http://cdn.highup-ent.com/assets/images/icon-prev.png');
}
.schedule .schedule-nav .next-month {
	background-image: url('http://cdn.highup-ent.com/assets/images/icon-next.png');
}
.schedule .event-source {
	display: none;
}
.schedule .event-list .event-item {
	display: flex;
	flex-wrap: wrap;
}
.schedule .event-list .event-date {
	line-height: 1.8;
	opacity: 0.3;
	width: 40px;
}
.schedule .event-list .event-content {
	font-size: 15px;
	margin-bottom: 15px;
	width: calc(100% - 40px);
}
.schedule .event-list .event-content br {
	display: block !important;
}
.schedule .event-list h3 {
	margin-bottom: 30px;
}
.schedule .event-list .no-events {
	font-size: 15px;
	opacity: 0.3;
}

.schedule .schedule-nav,
.schedule .calendar {
	transition-delay: 0.3s;
}

.schedule .calendar {
	float: right;
	padding: 0 5px;
}
.schedule .calendar table {
	border-spacing: 0;
	border-collapse: collapse;
	width: 100%;
}
.schedule .calendar table thead th,
.schedule .calendar table tbody td {
	padding: 15px 0;
	width: 14.2857%;
	text-align: center;
}
.schedule .calendar table tbody td {
	opacity: 0.3;
}
.schedule .calendar table tbody td span {
	display: inline-block;
	padding: 5px;
}
.schedule .calendar table tbody td.has-event,
.schedule .calendar table tbody td.today {
	opacity: 1;
}
.schedule .calendar table tbody td.today span {
	border-bottom: 2px solid #fff;
}

@media (max-width: 700px) {
	.schedule .section-heading {
		margin-bottom: 0;
		position: relative;
		line-height: 0.9;
	}
	.schedule .section-liner {
		padding: 0;
	}
	.schedule .section-liner > span {
		display: none;
	}
	.schedule .section-content > * {
		width: 100%;
		float: none;
	}
	.schedule .schedule-nav {
		margin-left: 0;
	}
	.schedule .schedule-nav h3 {
		margin: 0;
	}
	.schedule .schedule-nav .button {
		position: absolute;
		top: 5px;
		width: 26px;
		height: 26px;
	}
	.schedule .schedule-nav .button:hover {
		opacity: 0.35;
	}
	.schedule .schedule-nav .prev-month {
		right: 45px;
	}
	.schedule .schedule-nav .next-month {
		right: 0;
	}
	.schedule .calendar {
		padding: 0;
		margin: 0 -12px 20px;
		width: calc(100% + 24px);
	}
	.schedule .calendar table thead th,
	.schedule .calendar table tbody td {
		padding: 3px 0;
		font-size: 13px;
	}
	.schedule .event-list {
		border-top: 2px solid rgba(255,255,255,0.15);
		padding-top: 25px;
	}
	.schedule .event-list .event-content {
		font-size: 14px;
	}
}

/*--- Discography ---*/

.album-tracklist .track-item:first-child {
	border-top: 2px solid rgba(255,255,255,0.15);
}
.album-tracklist .track-item {
	border-bottom: 2px solid rgba(255,255,255,0.15);
	display: flex;
	flex-wrap: wrap;
	font-size: 17px;
	padding: 20px 10px;
	position: relative;
}
.album-tracklist .track-item .track-number {
	width: 70px;
}
.album-tracklist .track-item .track-name {
	width: calc(100% - 70px);

}
.album-tracklist .track-item .track-name .participants {
	display: block;
	font-size: 12px;
	opacity: 0.5;
}
.album-tracklist .track-item .track-length {
	position: absolute;
	top: 20px;
	right: 0;
}
.album-tracklist .album-links {
	margin-top: 40px;
}
.album-tracklist .album-links span {
	display: block;
	font-size: 14px;
	opacity: 0.5;
	margin-right: 20px;
}
.album-tracklist .album-links .text-button {
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	margin-top: 5px;
	margin-right: 15px;
}

.video-holder {
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 56.25%;
}
.video-holder iframe {
	border: none;
	outline: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-width: auto;
	min-height: auto;
	z-index: 1;
}
.video-holder .video-cover {
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;

	display: none;
}

.album-photos .section-heading {
	margin-bottom: 15px;
}
.photo-grid {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -20px;
}
.photo-grid figure {
	width: 50%;
	padding: 20px;
}
.photo-grid figure.landscape {
	width: 100%;
}
.photo-grid figure img {
	width: 100%;
	height: auto;
}

@media (max-width: 600px) {
	.album-tracklist .track-item {
		font-size: 14px;
		padding: 15px 5px;
	}
	.album-tracklist .track-item .track-number {
		width: 30px;
	}
	.album-tracklist .track-item .track-name {
		width: calc(100% - 30px);
	}
	.album-tracklist .track-item .track-length {
		top: 15px;
	}
	.album-tracklist .album-links span {
		font-size: 13px;
	}
	.album-tracklist .album-links .text-button {
		font-size: 15px;
		margin-right: 10px;
	}
	.photo-grid {
		margin: 0 -5px;
	}
	.photo-grid figure {
		padding: 5px;
	}
}

.pswp .pswp__bg {
	background-color: rgba(0,0,0,0.87);
}
.pswp .pswp__button,
.pswp .pswp__share-modal,
.pswp .pswp__top-bar .pswp__counter {
	display: none;
}
.pswp .pswp__top-bar {
	position: relative;
}
.pswp .pswp__top-bar .pswp__button--close {
	background-color: transparent;
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0NCA0NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQgNDQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzMy42MSwxMi4zOSAzMS42MSwxMC4zOSAyMiwyMCAxMi4zOSwxMC4zOSAxMC4zOSwxMi4zOSAyMCwyMiAxMC4zOSwzMS42MSAxMi4zOSwzMy42MSAyMiwyNCAKCTMxLjYxLDMzLjYxIDMzLjYxLDMxLjYxIDI0LDIyICIvPgo8L3N2Zz4K') !important;
	background-size: 30px 30px;
	background-position: center center;
	border: none;
	display: block;
	outline: none;
	position: absolute;
	top: 25px;
	right: 28px;
	width: 40px;
	height: 40px;
}

@media (max-width: 600px) {
	.pswp .pswp__top-bar .pswp__button--close {
		top: 16px;
		right: 13px;
	}
}

/*--- Site Footer ---*/

.site-footer {
	padding-bottom: 60px;
}
.site-footer .brand-copy {
	font-family: 'Gilroy', 'Noto Sans KR', sans-serif;
	text-transform: uppercase;
	line-height: 1.25;
	margin-bottom: 20px;
}
.site-footer .copyright {
	font-size: 10px;
}

@media (max-width: 600px) {
	.site-footer .copyright {
		font-size: 9px;
	}
}

/*--- News ---*/

.news {
	margin-top: 67px;
	margin-bottom: 120px;
}

.news-grid {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -30px;
}
.news-grid .grid-item {
	width: 50%;
	padding: 30px;
}
.news-grid .grid-item img {
	border-radius: 3px;
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}
.news-grid .grid-item .headline {
	font-size: 28px;
	line-height: 1.35;
	width: 90%;
	margin-bottom: 10px;
}
.news-grid .grid-item .date {
	display: block;
}
.section.active .news-grid .grid-item .date {
	opacity: 0.3;
}

@media (max-width: 1800px) {
	.news-list .section-content {
		padding-right: 160px;
	}
}

@media (max-width: 1400px) {
	.news {
		margin-top: 60px;
	}
	.news-list .section-content {
		padding-right: 120px;
	}
}

@media (max-width: 1100px) {
	.news {
		margin-top: 150px;
	}
	.news-list .section-content {
		padding-right: 80px;
	}
	.news-grid {
		margin: 0 -20px;
	}
	.news-grid .grid-item {
		padding: 20px;
	}
}

@media (max-width: 600px) {
	.news {
		margin-top: 120px;
	}
	.news-list .section-content {
		padding-right: 45px;
	}
	.news-grid {
		margin: 0;
	}
	.news-grid .grid-item {
		padding: 20px 0;
		width: 100%;
	}
	.news-grid .grid-item img {
		margin-bottom: 20px;
	}
	.news-grid .grid-item .headline {
		font-size: 22px;
	}
	.news-grid .grid-item .date {
		font-size: 13px;
	}
}

.news-content article {
	width: 100%;
	max-width: 640px;
	margin: 0 auto;
}
.news-content article .article-header {
	margin: 120px 0;
}
.news-content article .article-header .date {
	margin-top: 20px;
	margin-bottom: 0;
	opacity: 0.3;
}
.news-content article p {
	text-align: justify;
	margin-bottom: 30px;
	word-break: break-all;
}
.news-content article .wp-block-image {
	margin: 60px 0;
}
.news-content article .wp-block-image img {
	width: 100%;
	height: auto;
}
.news-content article .wp-block-image figcaption {
	font-size: 14px;
	opacity: 0.5;
}

@media (max-width: 1100px) {
	.news-content article .article-header {
		margin: 80px 0;
	}
}

@media (max-width: 600px) {
	.news-content article .article-header {
		margin: 45px 0;
	}
	.news-content article .article-header .date {
		margin-top: 10px;
	}
	.news-content article .wp-block-image {
		margin: 35px 0;
	}
	.news-content article .wp-block-image figcaption {
		font-size: 13px;
	}
}

/*--- Audition ---*/

.audition-description .section-heading {
	font-weight: 900;
	line-height: 1.35;
}
.audition-description .section-content p:nth-child(odd) {
	border-top: 2px solid rgba(255,255,255,0.15);
	font-weight: 700;
	margin-bottom: 15px;
	padding-top: 45px;
}
.audition-description .section-content p:nth-child(even) {
	font-size: 16px;
	margin-top: 0;
	margin-bottom: 45px;
}

@media (max-width: 600px) {
	.audition-description .section-content p br {
		display: block;
	}
	.audition-description .section-content p:nth-child(odd) {
		font-size: 16px;
		margin-bottom: 15px;
		padding-top: 35px;
	}
	.audition-description .section-content p:nth-child(even) {
		font-size: 13px;
		margin-bottom: 30px;
	}
}

/*--- About ---*/

.about .page-background {
	opacity: 0.7;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	transition: opacity 2s cubic-bezier(0.25, 1, 0.5, 1);
}
.about.hide-background .page-background {
	opacity: 0;
}
.about .page-background video {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	height: auto;
	min-width: 100%;
	min-height: 100%;
}
.about .page-content,
.about .site-footer {
	position: relative;
	z-index: 2;
}

.about .manifesto .copy {
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
}
.about .manifesto .copy p {
	font-size: 25px;
	font-weight: 900;
	margin-bottom: 55px;
	transition: opacity 2s cubic-bezier(0.25, 1, 0.5, 1), transform 3s cubic-bezier(0.25, 1, 0.5, 1);
}
.about.loading .manifesto .copy p {
	opacity: 0;
	transform: translateY(50px);
}

.about .office-info {
	margin-top: 50vh;
	margin-top: calc(var(--vh, 1vh) * 50);
}

.about .site-components .nav-line {
	background-color: rgba(255,255,255,0.15);
}

@media (max-width: 600px) {
	.about .manifesto .copy p {
		font-size: 18px;
		margin-bottom: 30px;
		text-align: justify;
		word-break: break-all;
	}
}

/*--- Single Page ---*/

.single-page {
	margin-top: 67px;
	margin-bottom: 120px;
}
.single-page img {
	width: 100%;
	height: auto;
}

@media (max-width: 1400px) {
	.single-page {
		margin-top: 60px;
	}
}

@media (max-width: 1100px) {
	.single-page {
		margin-top: 150px;
	}
}

@media (max-width: 600px) {
	.single-page {
		margin-top: 120px;
	}

}

/*--- 404 Error ---*/

.error404 h2 {
	line-height: 1.1;
}
.error404 .site-components .breadcrumbs,
.error404 .site-footer {
	display: none;
}

img.emoji,
img.wp-smiley {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: 0 0 !important;
  padding: 0 !important;
}
